<template>
  <section>
    <b-field grouped group-multiline>
      <b-select v-model="pagination_perPage">
        <option value="1">1 per page</option>
        <option value="10">10 per page</option>
        <option value="150">150 per page</option>
        <option value="2000">2000 per page</option>
      </b-select>

      <b-checkbox v-model="formFilterByNotSent" class="level-left">Filter by "not Sent"</b-checkbox>
    </b-field>

    <b-table
      :data="pagination_paginatedData"
      :backend-pagination="!pagination_allLoaded"
      pagination-simple
      paginated
      detailed
      :backend-sorting="!pagination_allLoaded"
      :total="pagination_totalItemsEstimation"
      :per-page="pagination_perPage"
      :checked-rows.sync="pagination_checkedRows"
      :default-sort="[pagination_sortField, pagination_sortDirection]"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      checkable
      narrowed
      @page-change="pagination_onPageChange"
      @sort="pagination_onSort"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        placeholder="Search..."
        icon="search"
        size="is-small"
      />
      <template slot="detail" slot-scope="props">
        <b-notification
          v-if="props.row.delivery && props.row.delivery.error && props.row.delivery.error.trim() !== ''"
          type="is-danger"
          :closable="false"
        >
          <b>Error Message:</b>
          <br />
          {{ props.row.delivery.error }}
        </b-notification>
        <VEditorHtml :html="props.row.message.html" :sanitize-html-config="sanitizeHtmlConfig" />
      </template>

      <b-table-column
        field="tenantName"
        label="Tenant Name"
        :sortable="pagination_allLoaded"
        :searchable="pagination_allLoaded"
      >
        <template #default="props">{{ props.row.tenantName }}</template>
      </b-table-column>

      <b-table-column
        field="delivery.state"
        label="State"
        width="100"
        sortable
        :searchable="pagination_allLoaded"
      >
        <template #default="props">
          <!-- show state as tag and color accordingly -->
          <b-tag
            :type="props.row.delivery && props.row.delivery.state === 'SUCCESS'
              ? 'is-success'
              : props.row.delivery && props.row.delivery.state === 'ERROR'
                ? 'is-danger'
                : ''"
            size="is-small"
          >{{ props.row.delivery && props.row.delivery.state }}</b-tag>
        </template>
      </b-table-column>

      <b-table-column field="from" label="From" sortable :searchable="pagination_allLoaded">
        <template #default="props">{{ props.row.from }}</template>
      </b-table-column>

      <b-table-column field="to" label="To" sortable :searchable="pagination_allLoaded">
        <template #default="props">{{ props.row.to }}</template>
      </b-table-column>

      <b-table-column field="message.subject" label="Subject" sortable>
        <template #default="props">{{ props.row.message.subject }}</template>
      </b-table-column>

      <b-table-column
        field="_meta.dateCreated"
        label="Date Created"
        sortable
        centered
        :searchable="pagination_allLoaded"
      >
        <template #default="props">
          {{ props.row._meta.dateCreated.toDate().toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          }) }}
        </template>
      </b-table-column>

      <b-table-column label="Actions" sortable :searchable="pagination_allLoaded">
        <template #default="props">
          <!-- if state is not SUCCESS, show button to retry -->
          <b-button
            v-if="props.row.delivery && props.row.delivery.state !== 'SUCCESS'"
            :loading="props.row.isRetrying"
            icon-left="sync-alt"
            size="is-small"
            type="is-warning"
            @click="onRetry(props.row)"
          >
            Retry
            <!-- if more than one retry attempts, show it -->
            <span
              v-if="props.row.delivery && props.row.delivery.attempts > 0"
            >({{ props.row.delivery.attempts }})</span>
          </b-button>
          <span
            v-else-if="props.row.delivery && props.row.delivery.attempts > 0"
          >(send attempts: {{ props.row.delivery.attempts }})</span>
        </template>
      </b-table-column>
    </b-table>

    <b-loading
      :is-full-page="false"
      :active.sync="pagination_isPaginationLoading"
      :can-cancel="false"
    />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit, faSearch, faSyncAlt
} from '@fortawesome/free-solid-svg-icons'

import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import { mixins } from 'vue-class-component'
import { EmailDB } from '@/types/typeEmail'
import db from '@/firebase'
import { typedPartialUpdatePayload, typedWhereV9 } from '@/database/dbHelper'
import TenantManager from '@/database/tenantManager'
import { DataCache } from '@/helpers/dataCache'
import { TenantDB } from '@/types/typeTenant'
import { hasDBid } from '@/types/typeGeneral'
import databaseSchema from '@/database/databaseSchema'
import VEditorHtml from '@/components/global/VEditorHtml.vue'
import sanitize from 'sanitize-html'
import { FilterConfigNew } from '@/database/filterUtil'
import { collection, getCountFromServer } from 'firebase/firestore'
import { MenuNotifications } from '@/components/global/VNotificationCounts.vue'

library.add(faEdit, faSearch, faSyncAlt)


export async function getMenuNotification(): Promise<MenuNotifications> {
  // get not sent emails

  const emailsErrorStateQuery = typedWhereV9(
    collection(db, databaseSchema.COLLECTIONS.EMAILS.__COLLECTION_PATH__()),
    { delivery: { state: 'ERROR' } }, '==', 'ERROR'
  )

  const emailsErrorStateQueryCount = (await getCountFromServer(emailsErrorStateQuery)).data().count

  return {
    info: { count: 0, text: '' },
    warning: { count: 0, text: '' },
    error: { count: emailsErrorStateQueryCount, text: 'Emails with error state' }
  }
}

@Component({
  components: {
    VEditorHtml
  }
})
export default class AdminEmailsList extends mixins<VPaginationMixin<EmailDB>>(VPaginationMixin) {
  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = '_meta.dateCreated'
  public pagination_perPage = 10
  public pagination_liveUpdateOnFirstPage = true

  protected pagination_collectionPath = databaseSchema.COLLECTIONS.EMAILS.__COLLECTION_PATH__()

  public formFilterByNotSent = false

  public sanitizeHtmlConfig: sanitize.IOptions = {
    allowedTags: ['div'],
    allowedAttributes: {
      div: ['style']
    },
    allowedSchemesByTag: {
      img: ['data', 'http', 'https', 'ftp', 'mailto', 'tel']
    },
    allowedStyles: {
      img: {
        'max-width': [/^\d+(px|em|rem|%)$/],
        'max-height': [/^\d+(px|em|rem|%)$/],
        'height': [/^\d+(px|em|rem|%)$/]
      },
      div: {
        'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'padding': [/^\d+(px|em|rem|%)$/],
        'border-radius': [/^\d+\.\d+(px|em|rem|%)$/]
      }
    }
  }

  @Watch('formFilterByNotSent')
  public onChangeFormFilterByNotSent() {
    this.pagination_getData(true)
  }


  protected pagination_filter(): FilterConfigNew<EmailDB>[] {
    return (this.formFilterByNotSent)
      ? [{
        fieldAccessor: { delivery: { state: 'SUCCESS' } },
        opStr: '!=',
        values: ['SUCCESS'],
        indexGroups: [],
        isMandatory: true
      }]
      : []
  }

  private dataCacheTenant = new DataCache<TenantDB & hasDBid>(async (key) => {
    return await TenantManager.get(key)
  })

  public async onRetry(row: EmailDB & hasDBid) {
    console.log(row)
    // set is retrying
    this.$set(row, 'isRetrying', true)

    // update email
    try {
      await db.collection(this.pagination_collectionPath).doc(row.id).update(
        typedPartialUpdatePayload<EmailDB>({
          delivery: {
            state: 'RETRY'
          }
        })
      )
    } catch (error) {
      this.$helpers.notification.Error(`Error updating email: ${error}`)
    } finally {
      this.$set(row, 'isRetrying', false)
    }
  }

  protected async pagination_foreachDoc(doc: EmailDB & hasDBid & { _local: { docPath: string } }) {
    // console.log(doc._docRef?.parent?.parent?.id)

    // if the tenantID is 'SYSTEM' then it is a system email
    if (doc.tenantID === 'SYSTEM') {
      this.$set(doc, 'tenantName', 'SYSTEM')
      this.$set(doc, 'tenantNumber', 0)
    } else if (doc.tenantID) {
      this.dataCacheTenant.get(doc.tenantID).then((d) => {
        this.$set(doc, 'tenantName', d.name)
        this.$set(doc, 'tenantNumber', d._number)
      }).catch((e) => {
        this.$helpers.notification.Error(`Error getting tenant: ${e}`)
        this.$set(doc, 'tenantName', doc.tenantID)
        this.$set(doc, 'tenantNumber', 0)
      })
    } else {
      this.$set(doc, 'tenantID', 'not assigned') // use 0 for proper sorting
      this.$set(doc, 'tenantNumber', 0)
    }
  }

  public created() {
    this.pagination_getData()
  }
}
</script>

<style lang="scss">
</style>
