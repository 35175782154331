<template>
  <section>
    <b-field grouped group-multiline>
      <VImportExport
        :export-docs="pagination_paginatedData"
        :import-export-definitions="importExport_importExportDefinitions"
        file-name="categories-export"
        @imported="$emit('updated')"
      />

      <b-select v-model="pagination_perPage">
        <option value="1">1 per page</option>
        <option value="10">10 per page</option>
        <option value="150">150 per page</option>
        <option value="2000">2000 per page</option>
      </b-select>

      <b-checkbox
        v-model="formFilterByTosAcceptPriv"
        class="level-left"
      >Filter by "Can Accept Terms"</b-checkbox>

      <b-checkbox
        v-model="showPrivileges"
        class="level-left"
      >Show Privileges</b-checkbox>
    </b-field>

    <b-table
      :data="pagination_paginatedData"
      :backend-pagination="!pagination_allLoaded"
      pagination-simple
      paginated
      :backend-sorting="!pagination_allLoaded"
      :total="pagination_totalItemsEstimation"
      :per-page="pagination_perPage"
      :checked-rows.sync="pagination_checkedRows"
      :default-sort="[pagination_sortField, pagination_sortDirection]"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      checkable
      narrowed
      @page-change="pagination_onPageChange"
      @sort="pagination_onSort"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        placeholder="Search..."
        icon="search"
        size="is-small"
      />

      <!-- <b-table-column field="id" label="ID" width="10" sortable :searchable="pagination_allLoaded">
          <b-tooltip
            type="is-dark"
            multilined
            :label="props.row.id"
            animated
          >{{ props.row.id | truncateMid(8) }}</b-tooltip>
      </b-table-column>-->
      <b-table-column field="tenantName" label="Tenant Name" :searchable="pagination_allLoaded">
        <template #default="props">{{ props.row.tenantName }}</template>
      </b-table-column>
      <b-table-column field="tenantNumber" label="Tenant Number" :searchable="pagination_allLoaded">
        <template #default="props">{{ props.row.tenantNumber }}</template>
      </b-table-column>
      <b-table-column
        field="isActive"
        label="Active"
        width="100"
        :searchable="pagination_allLoaded"
      >
        <template #default="props">{{ props.row.isActive }}</template>
      </b-table-column>
      <b-table-column
        field="canAcceptTerms"
        label="Can Accept TOS"
        width="100"
        :searchable="pagination_allLoaded"
      >
        <template #default="props">{{ props.row.canAcceptTerms }}</template>
      </b-table-column>

      <b-table-column field="name" label="User Name" sortable :searchable="pagination_allLoaded">
        <template #default="props">{{ props.row.name }}</template>
      </b-table-column>

      <b-table-column field="email" label="Email" sortable>
        <template #default="props">{{ props.row.email }}</template>
      </b-table-column>

      <b-table-column
        field="_meta.dateCreated"
        label="Date Created"
        sortable
        centered
        :searchable="pagination_allLoaded"
      >
        <template #default="props">{{ props.row._meta.dateCreated.toDate().toLocaleDateString() }}</template>
      </b-table-column>

      <b-table-column
        field="privileges"
        label="Privileges"
        sortable
        :visible="showPrivileges"
      >
        <template #default="props">
          <VInputUserRolesTags v-model="props.row.privileges" />
        </template>
      </b-table-column>
    </b-table>

    <b-loading
      :is-full-page="false"
      :active.sync="pagination_isPaginationLoading"
      :can-cancel="false"
    />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit, faSearch
} from '@fortawesome/free-solid-svg-icons'

import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import { mixins } from 'vue-class-component'
import UserManager from '@/database/userManager'
import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import { UserReferenceDB } from '@/types/typeUser'
import db from '@/firebase'
import { dbPrivileges } from '@/helpers/privileges'
import TenantManager from '@/database/tenantManager'
import { DataCache } from '@/helpers/dataCache'
import { TenantDB } from '@/types/typeTenant'
import { hasDBid } from '@/types/typeGeneral'
import VImportExport, { typeImportExportDefinitions } from '@/components/VImportExport.vue'
import { FilterConfigNew } from '@/database/filterUtil'

library.add(faEdit, faSearch)

@Component({
  components: { VInputUserRolesTags, VImportExport }
})
export default class AdminUsersList extends mixins<VPaginationMixin<UserReferenceDB>>(VPaginationMixin) {
  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = 'email'
  public pagination_perPage = 10

  protected pagination_collectionGroupName = UserManager.getCollectionGroupName()

  public formFilterByTosAcceptPriv = false
  public showPrivileges = false

  @Watch('formFilterByTosAcceptPriv')
  public onChangeFormFilterByTosAcceptPriv() {
    this.pagination_getData(true)
  }


  protected pagination_filter(): FilterConfigNew<UserReferenceDB>[] {
    /**
     *     return (this.formFilterByTosAcceptPriv)
      ? typedWhere<UserReferenceDB>(query, { privileges: [] }, 'array-contains', dbPrivileges.TOS_ACCEPT)
      : query
     */
    return (this.formFilterByTosAcceptPriv)
      ? [{
        fieldAccessor: { privileges: [] },
        opStr: 'array-contains',
        values: [dbPrivileges.TOS_ACCEPT],
        indexGroups: [],
        isMandatory: false
      }]
      : []
  }


  private dataCacheTenant = new DataCache<TenantDB & hasDBid>(async (key) => {
    return await TenantManager.get(key)
  })

  protected async pagination_foreachDoc(doc: UserReferenceDB & hasDBid & { _local: { docPath: string } }) {
    console.log(doc._local.docPath)

    if (db.doc(doc._local.docPath)?.parent?.parent?.id) {
      this.dataCacheTenant.get(db.doc(doc._local.docPath)?.parent?.parent?.id || '').then((d) => {
        this.$set(doc, 'tenantID', d.id)
        this.$set(doc, 'tenantName', d.name)
        this.$set(doc, 'tenantNumber', d._number)
      }).catch((e) => {
        this.$helpers.notification.Error('Error loading tenant data: ' + String(e))
      })
    } else {
      this.$set(doc, 'tenantID', 'not assigned') // use 0 for proper sorting
      this.$set(doc, 'tenantName', 'not assigned')
      this.$set(doc, 'tenantNumber', 0)
    }
    this.$set(doc, 'canAcceptTerms', doc.privileges.includes(dbPrivileges.TOS_ACCEPT).toString())
    this.$set(doc, 'isActive', doc.active.toString())
  }

  public created() {
    this.pagination_getData()
  }

  public importExport_importExportDefinitions: typeImportExportDefinitions<any> = [
    {
      readOnly: true,
      exportColumnName: 'Name',
      exportFormatter: (d: UserReferenceDB) => d.name
    }, {
      readOnly: true,
      exportColumnName: 'Email',
      exportFormatter: (d: UserReferenceDB) => d.email
    }, {
      readOnly: true,
      exportColumnName: 'DateCreated',
      exportFormatter: (d: UserReferenceDB) => d._meta.dateCreated.toDate().toString()
    }, {
      readOnly: true,
      exportColumnName: 'tenantID',
      exportFormatter: (d: any) => d.tenantID
    }, {
      readOnly: true,
      exportColumnName: 'tenantName',
      exportFormatter: (d: any) => d.tenantName
    }, {
      readOnly: true,
      exportColumnName: 'tenantNumber',
      exportFormatter: (d: any) => d.tenantNumber
    }, {
      readOnly: true,
      exportColumnName: 'canAcceptTerms',
      exportFormatter: (d: any) => d.canAcceptTerms
    }, {
      readOnly: true,
      exportColumnName: 'Privileges',
      exportFormatter: (d: UserReferenceDB) => d.privileges
    }
  ]
}
</script>

<style lang="scss">
</style>
